import React, { useState, useEffect, useContext } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Input from "../../components/inputs/input";
import { FaAngleDoubleDown } from "react-icons/fa";
import Purchase from "../purchase/purchase";
import PurchaseForm from "../purchase/purchase-form";
import PurchaseStatus from "../purchase/purchase-status";
import About from "../about-us/about";
import Overview from "../overview/overview";
import Home from "../../pages/home/home";
import { Switch, Route, withRouter } from "react-router-dom";
import Btn from "../../components/button/menu-button";
import { MdDashboard, MdAccountBalanceWallet } from "react-icons/md";
import TapAndPlayIcon from "@material-ui/icons/TapAndPlay";
import { FaFileInvoice } from "react-icons/fa";
import { Link } from "react-router-dom";
import "../../index.css";
import styles from "../../components/sidenav/sidenav.module.css";
import Faq from "../../components/FAQ/faq";
import Support from "../../components/support/support";
import Dashboard from "../../pages/dashboard/dashboard";
import AllTransactionHistory from "../../pages/Transaction-History/index";
import UpdateProfile from "../../components/profiles/update-profile";
import ProfileDropdown from "../../components/profile-dropdown/profile-dropdown";
import MySnackbarContent from "../../components/alert-content";
import Snackbar from "@material-ui/core/Snackbar";
import { alertActions } from "../../_actions/alert.actions";
import { AlertContext } from "../../context/alert-context";
import { ModalContext } from "../../context/modal-context";
import TAF from "../Tell a friend/tell-a-friend";
import FundTransfer from "../fund-transfer/fund-transfer";
import { PrivateRoute } from "../../components/navigations/PrivateRoute";
import { UserContext } from "../../context/user-context";
import { TransactionProvider } from "../../context/transaction-context";
import { TranHistoryProvider } from "../../context/transaction-history-context";
import CookiesPolicy from "../Policy/cookies-policy";
import PrivacyPolicy from "../Policy/privacy-policy";
import OtherServices from "../other-transactions/other-services";
import ConfirmAccount from "../signup/account-confirmation";
import BackUpEmailConfirmation from "../login/backup-email-confirmation";
import ResetPassword from "../login/reset-password";
import { TellAFriendContextProvider } from "../../context/tell-a-friend-context";
import { SupportContextProvider } from "../../context/support-context";
import { ActivationContextProvider } from "../../context/activation-context";
import { BackupEmailUpdateContextProvider } from "../../context/backupEmail-update-context";
import { AccountResetContextProvider } from "../../context/account-reset-context";
import Notification from "../Notification/notification";
import LogOut from "../logout/logout";
import BulkUploadForm from "../bulk-upload/bulk-upload-form";
import Tickets from "../tickets";
import Events from "../tickets/events";
import Movies from "../tickets/movies";
import BulkUploadPreview from "../bulk-upload/bulkupload-preview";
import EventTicketSummary from "../tickets/event-ticket-summary";
import MovieTicketSummary from "../tickets/movie-ticket-summary";
import EventTicketForm from "../tickets/event-ticket-form";
import MovieTicketForm from "../tickets/movie-ticket-form";
import XpressWallet from "../xpress-wallet";
import TopUpWallet from "../xpress-wallet/topup-wallet";
import WalletTopupSummary from "../xpress-wallet/wallet-topup-summary";
import BulkUploadNotification from "../bulk-upload/bulk-upload-notification";
import MovieNotification from "../tickets/movie-notification";
import EventNotification from "../tickets/event-notification";
import GuessAndWin from "../../components/guessandwin/index";
import { ProductContext } from "../../context/product-context";

// import GuessAndWin from "../../components/guessandwin";
// import LogOut from "../../components/log-out";

import { ThemeProvider } from "@material-ui/core";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  // listItem:{
  //   display:'none'
  // },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.up("lg")]: {},
    marginBottom: "5px",
    background: "white",
    // boxShadow: "0 0px 4px rgba(0,0,0,0.05), 0 1px 4px rgba(0,0,0,0.05)",
    boxShadow:
      "0 5px 10px rgba(154,160,185,.05), 0 15px 40px rgba(166,173,201,.2)",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      width: "59%",
      boxShadow: "0px 0px 0px rgba(0,0,0,0.11)",
    },
    [theme.breakpoints.down("xs")]: {
      width: "57.8%",
      boxShadow: "0px 0px 0px rgba(0,0,0,0.11)",
      zIndex: "1",
    },
  },
  menuButton: {
    marginRight: 36,
    position: "absolute",
    left: "15px",
    "&:focus": {
      outline: "none",
    },
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    "&:focus": {
      outline: "none",
    },
  },
  iconImg: {
    marginTop: "15px",
    height: "35px",
    width: "35px",
    [theme.breakpoints.down("sm")]: {
      width: "25px",
      height: "25px",
    },
  },
  dashboardIcon: {
    fontSize: "35px",
    color: "#FF6C00",
    marginTop: "50px",
    marginLeft: "-5px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "25px",
      marginLeft: "-2px",
    },
  },
  moreServicesIcon: {
    color: "#FF6C00",
    marginLeft: "19px",
    fontSize: "35px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "25px",
      marginLeft: "15px",
    },
  },
  style: {
    boxSizing: "border-box",
    // boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
    boxShadow: "5px 7px 30px -10px rgba(150,170,180,0.5)",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      width: 180,
    },
  },
  hideLogo: {
    display: "none",
  },
  showLogo: {
    display: "block",
    width: "50px",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: "0px !important",
    marginTop: "-60px",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: "0px",
    height: "550px",
    // background: "red",
    boxSizing: "content-box",
    // marginTop: "300px",
    borderRadius: "5px",
    marginBottom: "150px",
    marginLeft: "40px",
    marginRight: "30px",
    overflowY: "auto",
    overflowX: "hidden",
    position: "relative",
    display: "block",
    "&::webkitScrollBar": {
      background: "none",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "550px",
      marginTop: "65px",
      overflowY: "scroll",
      marginLeft: "2px",
      marginRight: "2px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "70px",
      marginLeft: "5px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "70px",
      // backgroundColor: 'orange',
    },
  },
  brand: {
    textAlign: "left",
    flex: 1,
    marginLeft: "50px",
    marginTop: "2px",
    marginBottom: "0px",
    padding: "0px",
  },
}));
export default function MiniDrawer() {
  const classes = useStyles();
  const { message, type, dispatch } = useContext(AlertContext);
  const { loggedIn } = useContext(UserContext);
  const { categories, loading } = useContext(ProductContext);

  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const width = window.innerWidth;
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [sideNavItems, setSideNavItems] = useState([]);

  const getData = () => {
    fetch("../resources/sidebar_categories.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        setSideNavItems(data);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (width <= 600) handleDrawerClose();
  }, [width]);

  const filteredMenu = categories.filter(function (category) {
    return (
      sideNavItems.filter(function (sideNavItem) {
        return category.CategoryCode == sideNavItem.categoryCode;
      }).length == 0
    );
  });
  const closeAlert = () => {
    dispatch(alertActions.clear());
  };

  return (
    <div className={classes.root}>
      <AppBar
        color="transparent"
        elevation={0}
        position="fixed"
        width="75%"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.Toolbar}>
          <div className={classes.brand}>
            <GuessAndWin className={classes.brand} />
          </div>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Header open={open} />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, classes.style, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <Link to="/">
          <img
            className={clsx(classes.drawer, {
              [classes.showLogo]: open,
              [classes.hideLogo]: !open,
            })}
            src="/resources/images/logo.png"
            alt="Logo"
            id="logo"
            style={{ marginLeft: "35px" }}
          />
        </Link>

        <div className={classes.toolbar}>
          <IconButton
            onClick={handleDrawerClose}
            className={clsx(classes.drawer, {
              [classes.showLogo]: open,
              [classes.hideLogo]: !open,
            })}
          >
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          {!!sideNavItems &&
            sideNavItems.map(({ id, url, text, icon, icon1, img, img1 }) => (
              text !== "Pay Bills" &&
              <ListItem button dense key={id} className={styles.listItem}>
                <Link to={`/${url}`}>
                  {!open ? (
                    <ListItemIcon>
                      {img1 ? (
                        <img src={img1} className={classes.iconImg} />
                      ) : (
                        <MdDashboard
                          className={classes.dashboardIcon}
                          style={{}}
                        />
                      )}
                    </ListItemIcon>
                  ) : (
                    <Btn
                      menu
                      id={id}
                      component={Link}
                      to={url}
                      style={{
                        width: "100%",
                        height: "60px",
                        fontSize: "15px",
                        textAlign: "center",
                      }}
                      text={text}
                      Icon={icon}
                      img={img}
                    />
                  )}
                </Link>
              </ListItem>
            ))}
        </List>
        {open ? (
          <>
            <div className="sidenav-dropdown">
              <div className={styles.dropdown}>
                <h5
                  className="dropdown-toggle"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                 Pay Bills
                </h5>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                  style={{
                    background: "black !important",
                    color: "red !important",
                  }}
                >
                  {!!filteredMenu &&
                    filteredMenu.map(({ CategoryName, UrlPath }) => (
                      <>
                        <Link
                          to={`/${UrlPath}`}
                          className="dropdown-item"
                          style={{
                            display: "block",
                            textDecoration: "none",
                            textAlign: "center",
                            color: "#000",
                            fontSize: "14px",
                          }}
                        >
                          {CategoryName}
                        </Link>
                      </>
                    ))}
                </div>
              </div>
            </div>
            <div
              style={{
                marginLeft: "65px",
                marginTop: "5px",
                "&:focus": {
                  cursor: "pointer",
                  color: "#FF6C00",
                },
              }}
            >
              {/* {loggedIn && <ProfileDropdown />} */}
              <div className="dropdown">
                <h5
                  className="dropdown-toggle"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  QUICKLINKS
                </h5>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <Link
                    className="dropdown-item"
                    style={{
                      color: "black",
                      textDecoration: "none",
                      fontSize: "14px",
                    }}
                    to="/faq"
                  >
                    FAQ{" "}
                  </Link>
                  <Link
                    style={{
                      color: "black",
                      textDecoration: "none",
                      fontSize: "14px",
                    }}
                    to="/tell-a-friend"
                    className="dropdown-item"
                  >
                    Tell A Friend
                  </Link>
                  <Link
                    style={{
                      color: "black",
                      textDecoration: "none",
                      fontSize: "14px",
                    }}
                    to="/contact"
                    className="dropdown-item"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </>
        ) : (
          <FaAngleDoubleDown
            onClick={handleDrawerOpen}
            className={classes.moreServicesIcon}
          />
        )}
      </Drawer>
      <main className={classes.content}>
        <Route exact path="/index" component={Home} />
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/overview" component={Overview} />
        <Route exact path="/faq" component={Faq} />
        <Route exact path="/logout" component={LogOut} />
        <TellAFriendContextProvider>
          <Route exact path="/tell-a-friend" component={TAF} />
        </TellAFriendContextProvider>
        <Route exact path="/cookies-policy" component={CookiesPolicy} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <SupportContextProvider>
          <Route exact path="/contact" component={Support} />
        </SupportContextProvider>

        {/* <PrivateRoute
          loggedIn={loggedIn}
          exact
          path="/Collections"
          component={Purchase}
        />
        <PrivateRoute
          loggedIn={loggedIn}
          exact
          path="/Pension"
          component={Purchase}
        />
        <PrivateRoute
          path="/Transfer"
          component={Purchase}
          loggedIn={loggedIn}
          exact
        /> */}

        <Switch>
          <Route exact path="/airtime/bulk-upload" component={BulkUploadForm} />
          <Route
            exact
            path="/bulkupload-preview"
            component={BulkUploadPreview}
          />
           <Route
            exact
            path="/bulk-upload/status"
            component={BulkUploadNotification}
          />
          <Route
            exact
            path="/bulk-upload-status"
            component={BulkUploadNotification}
          />
         
          <Route exact path="/tickets/event" component={Events} />
          <Route exact path="/tickets/movie" component={Movies} />
          {/* <Route
            exact
            path="/xpress-wallet/fund-wallet"
            component={FundWallet}
          /> */}
          <Route exact path="/topup-wallet" component={TopUpWallet} />
          <Route exact path="/status" component={Notification} />
          <Route exact path="/:path/status" component={Notification} />
          {categories.map((route) => (
            <Route
              key={route.CategoryCode}
              path={`/${route.UrlPath}`}
              component={Purchase}
              exact
            />
          ))}
          {categories.map((route) => (
            <Route
              exact
              key={route.CategoryCode}
              path={`/${route.UrlPath}/:billerCode`}
              component={PurchaseForm}
            />
          ))}
          {categories.map((route) => (
            <Route
              exact
              key={route.CategoryCode}
              path={`/${route.UrlPath}/:billerCode/:id`}
              component={PurchaseForm}
            />
          ))}
          <Route
            exact
            path="/xpress-wallet/topup-wallet/order-summary"
            component={WalletTopupSummary}
          />
          <Route
            exact
            path="/tickets/events/book-ticket"
            component={EventTicketForm}
          />
          <Route
            exact
            path="/tickets/movies/book-ticket"
            component={MovieTicketForm}
          />
          <Route
            exact
            path="/tickets/events/order-summary"
            component={EventTicketSummary}
          />
          <Route
            exact
            path="/tickets/movie/order-summary"
            component={MovieTicketSummary}
          />
             <ActivationContextProvider>
            <Route
              exact
              path="/account-activation"
              component={ConfirmAccount}
            />
          </ActivationContextProvider>
          <Route exact path="/events/status" component={EventNotification} />
          {/* test */}
          <Route exact path="/events-status" component={EventNotification} />
          <Route exact path="/movies/status" component={MovieNotification} />
          {/* test */}
          <Route exact path="/movies-status" component={MovieNotification} />
          {/* test */}
         

          <Route exact path="/product-review" component={PurchaseStatus} />

          <Route exact path="/coming-soon" component={OtherServices} />
          <AccountResetContextProvider>
            <Route exact path="/reset-password" component={ResetPassword} />
          </AccountResetContextProvider>
          {/* <Route exact path="/tickets" component={Tickets} /> */}
          {/* <Route exact path="/xpress-wallet" component={XpressWallet} /> */}
       
          <BackupEmailUpdateContextProvider>
            <Route
              exact
              path="/update-profile"
              component={BackUpEmailConfirmation}
            />
          </BackupEmailUpdateContextProvider>
          <PrivateRoute
            loggedIn={loggedIn}
            exact
            path="/upload-profile"
            component={UpdateProfile}
          />
          {/* <PrivateRoute
          loggedIn={loggedIn}
          exact
          path="/wallet"
          component={XpressWallet}
        /> */}
          <PrivateRoute
            loggedIn={loggedIn}
            exact
            path="/buy-tickets"
            component={OtherServices}
          />
        </Switch>

        <TransactionProvider>
          <PrivateRoute
            path="/dashboard"
            component={Dashboard}
            loggedIn={loggedIn}
            exact
          />
        </TransactionProvider>
        <TranHistoryProvider>
          <PrivateRoute
            exact
            path="/transaction-history"
            component={AllTransactionHistory}
            loggedIn={loggedIn}
          />
        </TranHistoryProvider>
        {message && (
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={true}
            autoHideDuration={60000}
            onClose={closeAlert}
          >
            <MySnackbarContent
              onClose={closeAlert}
              variant={type}
              message={message}
            />
          </Snackbar>
        )}
      </main>

      <Footer />
    </div>
  );
}
